<template>
  <div>
    <h2 class="rating_Text">
      Your overall rating
    </h2>
    <div class="star_rating_container">
      <button
        v-for="element in stars"
        :key="element"
        class="star_rating_container_main"
        @click="updateRating(element)"
        @mouseover="star_hover = element"
        @focus="star_hover = element"
        @blur="star_hover = 0"
        @mouseleave="star_hover = 0"
      >
        {{ $t("accessibility.star." + element) }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31.94"
          height="31.94"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M11.0625 9.79688L13 16L8 12.1562L3 16L4.9375 9.79688L0 6H6.125L8 0L9.875 6H16L11.0625 9.79688Z"
            class="star_element"
            :class="star_class(element)"
          />
        </svg>
      </button>
      <h3 v-show="star_selected !== 0" class="star_amount">
        {{ star_selected }}
      </h3>
    </div>
    <h3
      tabindex="0"
      class="additional_comments"
      @click="openModal()"
      v-on:keyup.enter="openModal()"
      :aria-label="$t('accessibility.additionalComments')"
    >
      Additional comments?
    </h3> 
  </div>
</template>

<script>
import { ref } from "vue";
import { store } from "@/data/store.js";
import { sendRequest } from "@/js/feedBack.js";

export default {
  setup() {
    const stars = ref([1, 2, 3, 4, 5]);
    const star_selected = ref(localStorage.getItem("starRating") || 0);
    const star_hover = ref(0);

    function updateRating(star) {
      star_selected.value = star;
      localStorage.setItem("starRating", star);
      sendRequest({ star_rating: star, site_name: "Surface for Business" });
    }

    function star_class(element) {
      if (star_selected.value >= element) {
        return "star_element_active";
      } else if (star_selected.value < element && element <= star_hover.value) {
        return "star_element_hover";
      }
    }

    function openModal() {
      store.commit("updateIsMenuOpen", false);
      store.commit("updateFeedBack", true);
    }
    return {
      star_selected,
      star_hover,
      stars,
      store,
      updateRating,
      star_class,
      openModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.rating_Text {
  font-family: Segoe UI;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  @include breakpoint("tablet") {
    margin: 0px;
  }
  @include breakpoint("mobile") {
    margin: 0px;
  }
}

.star_rating_container {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  @include breakpoint("tablet") {
    margin-top: 0px;
  }
  @include breakpoint("mobile") {
    margin-top: 0px;
  }
  &_main {
    background: none;
    border: none;
    margin-right: 3px;
    font-size: 0px;
  }
}
.star_element {
  fill: #b3b0ad;

  &_active {
    fill: #000000;
  }
  &_hover {
    fill: #0078d4;
  }
}

.star_amount {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  width: 34px;
  height: 39px;
  font-size: 26px;
  line-height: 150%;
  margin: 0;
  text-align: right;
  color: #4a4a4a;
}

.additional_comments {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  text-decoration-line: underline;
  color: #4a4a4a;
  margin-top: 15px;
  cursor: pointer;
}
</style>
