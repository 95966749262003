function fetchCsrfToken() {
    return new Promise((resolve, reject) => {
        const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
        let url = `${baseUrl}/api/GetCsrfToken`;

        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4 && this.status === 200) {
                const response = JSON.parse(this.responseText);
                localStorage.setItem('csrfToken', response.token);  // Store CSRF token
                resolve(response.token);  // Resolve with the token
            } else if (this.readyState === 4) {
                reject("Failed to fetch CSRF token");
            }
        });

        xhr.open("GET", url);
        xhr.send();
    });
}

function sendRequest(payLoad) {
    const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
    let url = `${baseUrl}/api/Feedback`;

    if (window.location.host !== "exploresurface.com") {
        url = `${baseUrl}/api/Feedback`;
    }

    // Check if token is available in localStorage
    let csrfToken = localStorage.getItem('csrfToken');

    // Define a function to send the request once the token is available
    const sendApiRequest = (token) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                // Handle the response
                console.log(this.responseText);
            }
        });

        xhr.open("POST", url);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("X-CSRF-TOKEN", token);  // Use the CSRF token
        xhr.send(JSON.stringify(payLoad));
    };

    if (!csrfToken) {
        console.info("CSRF token not found in localStorage... fetching");
        fetchCsrfToken().then(token => {
            sendApiRequest(token);  // Send the request once token is fetched
        }).catch(error => {
            console.error("Error fetching CSRF token:", error);
        });
    } else {
        sendApiRequest(csrfToken);  // Token is already available, proceed with the request
    }
}

export { sendRequest };
